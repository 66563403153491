import '../styles/globals.scss';
import 'swiper/swiper.scss';

import Amplify from 'aws-amplify';

import { GeneralConfigProvider } from '../components/general-config-provider';
import { UserProvider } from '../components/user-provider';
import { NextIntlProvider } from 'next-intl';

function MyApp({ Component, pageProps }) {
  Amplify.configure({
    aws_project_region: process.env.NEXT_PUBLIC_AWS_PROJECT_REGION,
    aws_cognito_region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: process.env.NEXT_PUBLIC_FRONTEND_URL,
      redirectSignOut: process.env.NEXT_PUBLIC_FRONTEND_URL,
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
    ssr: true,
  });

  return (
    <GeneralConfigProvider>
      <UserProvider>
        <NextIntlProvider messages={pageProps.lang}>
          <Component {...pageProps} />
        </NextIntlProvider>
      </UserProvider>
    </GeneralConfigProvider>
  );
}

export default MyApp;
