import { createContext, useEffect, useState } from 'react';
import {
  getCurrentUser,
  getCurrentUserRole,
  signOut,
} from '../services/cognito';
import { apiRestGet } from '../services/request';
import { Hub } from 'aws-amplify';
import { ROLES } from '../shared/constants';
import Router from 'next/router';

export const UserContext = createContext(null);

export function UserProvider(props) {
  const [user, setUser] = useState(null);
  const [modalUserRemoved, setModalUserRemoved] = useState<boolean>(false);

  const setModal = (state: boolean): void => {
    setModalUserRemoved(state);
  };

  const getUserInfo = async () => {
    const currentUser = await getCurrentUser();

    await getCurrentUserRole();

    if ((await getCurrentUserRole()) !== ROLES.ADMIN) {
      if (currentUser.attributes.email) {
        const currentUserInfo = await apiRestGet(
          process.env.NEXT_PUBLIC_AUTH_CONFIG_SERVICE_URL + 'user',
          {
            correo_electronico: currentUser.attributes.email,
          }
        );

        if (currentUserInfo && currentUserInfo.status === 404) {
          Router.push(
            '/completa-tu-perfil?email=' + currentUser.attributes.email
          ).then();
        }

        if (currentUserInfo !== undefined) {
          currentUser.info = currentUserInfo.data;
        }

        if (currentUserInfo.data.usuario.estado === 5) {
          await signOut();
          setModal(true);
        }

        if (currentUserInfo.data.usuario.id_rol === ROLES.TOURIST) {
          apiRestGet(
            process.env.NEXT_PUBLIC_AUTH_CONFIG_SERVICE_URL + 'carrito',
            { id_user: currentUserInfo.data.perfil.id_turista, tipo: 1 }
          ).then((response) => {
            currentUser.carrito = response.data;
          });
        }

        setUser(currentUser);
      }
    }
  };

  Hub.listen('auth', ({ payload: { event } }) => {
    switch (event) {
      case 'signIn':
        getUserInfo().then();
        console.info('Hub: signIn');
        break;
      case 'signUp':
        console.info('Hub: signUp');
        break;
      case 'signOut':
        console.info('Hub: signOut');
        setUser(null);
        break;
    }
  });

  useEffect(() => {
    if (user === null) {
      getUserInfo().then();
    }

    return () => {
      Hub.remove('auth', () => setUser(null));
    };
  }, []);

  useEffect(() => {
    // if (user) {
    //   console.log('user', user);
    // }
  }, [user]);

  return (
    <UserContext.Provider
      value={{ user, getUserInfo, modalUserRemoved, setModal }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
