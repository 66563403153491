export const ROLES = {
  TOURIST: 1,
  PST: 2,
  ADMIN: 3,
  AUTORITY_TOURIST: 4,
  NO_LOGGED: 5
};

export const LOGIN_ROUTES = {
  TOURIST: '/',
  PST: '/negocios/login',
  ADMIN: '/admin/login',
  AUTORITY_TOURIST: '/admin/login',
};

export const SERVICES_LIST_ROUTE = '/negocios/ofertas';
export const PACKAGES_LIST_ROUTE = '/negocios/ofertas';

export const DOCUMENT_TYPES = {
  1: 'Cédula de ciudadanía',
  2: 'Cédula de extranjería',
  3: 'Pasaporte',
  4: 'Documento de identificación extranjera',
};

export const DISCOUNTS_CUPONS_TYPE = {
  VALOR: 1,
  DISCOUNT: 2
};

export const CUPONS_STATES = {
  IN_PROGRESS: 1,
  PUBLISHED: 2,
  EXPIRED: 3,
};



export const TOURIST_SERVICE_TYPE = {
  TOURIST_ACTIVITY: 1,
  TOURIST_ROUTE: 2,
  ACCOMMODATION: 3,
  TRANSPORT: 4,
  GASTRONOMY: 5,
  THEME_PARK: 6,
  TOURIST_PACKAGE: 7,
};

export const TOURIST_SERVICE_TYPE_ENDPOINT = {
  1: 'touristActivity',
  2: 'touristRoute',
  3: 'lodging',
  4: 'transport',
  5: 'gastronomy',
  6: 'thematicPark',
};



export const TOURIST_SERVICE_TYPE_ICON = {
  1: 'backpack',
  2: 'route',
  3: 'bed',
  4: 'bus',
  5: 'restaurant',
  6: 'mountain',
  7: 'brochure',
};

export const SERVICE_ICONS = {
  1: 'wifi',
  2: 'bar',
  3: 'hot-bath',
  4: 'gym',
  5: 'spa',
  6: 'diamond',
  7: 'shopping-bags',
  8: 'brush-scissors',
  9: 'couple-table',
  10: 'parking',
  11: 'washing-machine',
  12: 'restaurant',
  13: 'bathroom',
  14: 'couple-table',
  15: 'baby',
  16: 'restaurant',
  17: 'air-conditioner',
  18: 'tv',
  19: 'Outlets',
  20: 'planch',
  21: 'Alquiler',
  22: 'Pool'
};

export const OFFER_STATUS = {
  IN_PROGRESS: 1,
  PUBLISHED: 2,
  HIDDEN: 3,
  BLOQ: 4,
};

export const IMAGES_ROUTE = process.env.NEXT_PUBLIC_STORAGE_URL;

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const DAYS_OF_THE_WEEK = [
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
  'Domingo',
];

export const DAYS_OF_THE_WEEK_LOCALE =
  [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
  ];


  export const nameExperience = [
    'green-experience',
    'red-experience',
    'blue-experience',
    'yellow-experience'
  ]

export const HOURS = [
  { name: '1', value: [1, 13] },
  { name: '2', value: [2, 14] },
  { name: '3', value: [3, 15] },
  { name: '4', value: [4, 16] },
  { name: '5', value: [5, 17] },
  { name: '6', value: [6, 18] },
  { name: '7', value: [7, 19] },
  { name: '8', value: [8, 20] },
  { name: '9', value: [9, 21] },
  { name: '10', value: [10, 22] },
  { name: '11', value: [11, 23] },
  { name: '12', value: [0, 12] },
];

export const MINUTES = [
  { name: '00', value: 0 },
  { name: '15', value: 15 },
  { name: '30', value: 30 },
  { name: '45', value: 45 },
];

export const HOURS_OF_THE_DAY = [
  {
    name: '7:00 AM',
    value: '7:00:00',
  },
  {
    name: '8:00 AM',
    value: '8:00:00',
  },
  {
    name: '9:00 AM',
    value: '9:00:00',
  },
  {
    name: '10:00 AM',
    value: '10:00:00',
  },
  {
    name: '11:00 AM',
    value: '11:00:00',
  },
  {
    name: '12:00 PM',
    value: '12:00:00',
  },
  {
    name: '1:00 PM',
    value: '13:00:00',
  },
  {
    name: '2:00 PM',
    value: '14:00:00',
  },
  {
    name: '3:00 PM',
    value: '15:00:00',
  },
  {
    name: '4:00 PM',
    value: '16:00:00',
  },
  {
    name: '5:00 PM',
    value: '17:00:00',
  },
  {
    name: '6:00 PM',
    value: '18:00:00',
  },
  {
    name: '7:00 PM',
    value: '19:00:00',
  },
  {
    name: '8:00 PM',
    value: '20:00:00',
  },
  {
    name: '9:00 PM',
    value: '21:00:00',
  },
  {
    name: '10:00 PM',
    value: '22:00:00',
  },
  {
    name: '11:00 PM',
    value: '23:00:00',
  },
];

export const AGENCY_CATEGORY_ID_FOR_PST = 1;
export const LODGIN_CATEGORY_ID_FOR_PST = 3;
export const GASTRONOMY_CATEGORY_ID_FOR_PST = 5;
export const EXTREME_ACTIVITY_CATEGORY_ID = 1;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCDStTxltF9XbKKOUApgG-ny8oF1-F_YXc';

export const DESENCRYPT_KEY_CRYPTO = '64lxH7QhMOLf0hudXP'

export const SERVICE_EDIT_STEP = {
  GENERAL_INFORMATION: 1,
  SERVICE_DETAILS: 2,
  PHOTOS: 3,
  AVAILABILITY_AND_PRICE: 4,
};

export const RNT_VALIDATION_STATUS = {
  1: ['Por validar', 'RNTtoValidate'],
  2: ['Aceptada', 'RNTvalidated'],
  3: ['Rechazado', 'RNTrejected'],
  4: ['No vigente', 'RNTexpired'],
  5: ['Validando', 'RNTvalidating'],
};

export const CATEGORIES_LINK = {
  1: 'travesia-natural',
  2: 'herencia-cultural',
  3: 'paraiso-litoral',
  4: 'experencia-mistica'
}
export const SUBCATEGORIES_LINK = {
  1: 'travesia-natural/termalismo',
  2: 'travesia-natural/montanas-y-volcanes',
  3: 'travesia-natural/avistamiento-de-fauna',
  4: 'travesia-natural/cuerpos-de-agua',
  5: 'travesia-natural/agroturismo',
  6: 'travesia-natural/ecoturismo',
  7: 'travesia-natural/aventura',
  8: 'travesia-natural/buceo',
  9: 'herencia-cultural/oficios-artesanales',
  10: 'herencia-cultural/recorridos-culturales',
  11: 'herencia-cultural/declaratorias-unesco',
  12: 'herencia-cultural/festividades-y-eventos',
  13: 'herencia-cultural/cocinas-tradicionales',
  15: 'herencia-cultural/rituales-ancestrales',
  17: 'paraiso-litoral/pueblos-litoral',
  18: 'paraiso-litoral/cientifico',
  19: 'experiencia-mistica/historia-y-arquitectura-religiosa',
  20: 'experiencia-mistica/romerias-y-peregrinaciones'
};

export const RNT_LOAD_STATUS = {
  1: ['Activo', 'RNTtoValidate'],
  2: ['Ejecucion', 'RNTvalidating'],
  3: ['Finalizado', 'RNTvalidated'],
  4: ['Error', 'RNTrejected']
}

export const RNT_UPDATE_STATUS = {
  1: ['Actualizado', 'RNTtoValidate'],
  2: ['Ejecucion', 'RNTvalidating'],
  3: ['Finalizado', 'RNTvalidated'],
  4: ['No se pudo actualizar', 'RNTrejected']
}

export const SCHEDULED_PROCESS_STATUS = {
  1: 'Programado',
  2: 'En ejecución',
  3: 'Finalizado',
  4: 'Error',
};

export const DURATION_TRANPOSRT = [
  '1 - 6',
  '6 - 12',
  '12 - 18',
  '24'
]

export const ADMIN_MENU_OPTIONS = [
  {
    icon: 'users',
    label: 'Usuarios del Portal Web',
    path: '/admin/usuarios',
  },
  {
    icon: 'upload',
    label: 'Actualizar RNTs',
    path: '/admin/subida-de-archivo',
  },
  {
    icon: 'circle-arrows',
    label: 'Validar RNTs',
    path: '/admin/validaciones-programadas',
  },
  {
    icon: 'requests',
    label: 'Solicitudes',
    path: '/admin/solicitudes',
  },
  {
    icon: 'events',
    label: 'Eventos',
    path: '/admin/eventos',
  },
  { icon: 'calendar', label: 'Temporadas', path: '/admin/temporadas' },
  {
    icon: 'flag',
    label: 'Reportes',
    path: '/admin/reportes',
  },
  {
    path: '/admin/cuenta-y-contrasena',
    label: 'Cuenta y contraseña',
    icon: 'padlock',
  },
  {
    path: '/admin/configuracion-puntos',
    label: 'Puntos',
    icon: 'unesco-declarations'
  }
];

export const ADMIN_AUTORITY_TOURIST = [
  {
    icon: 'events',
    label: 'Eventos',
    path: '/admin/eventos',
  },
];

export const ASC_ORDER = 'ASC';
export const DESC_ORDER = 'DESC';

export const REQUEST_TYPES = {

  RNT_NUMBER: 2,
  BUSINESS_NAME: 3,
  RNT_YEAR: 4,
};

export const ESPECIAL_CHARACTER = "$#=%#&es";

export const EVENT_TYPE = {
  ADMIN_RAP: "1",
  AUTHORITY_TOURIST: "2"

}

export const TYPE_SOLICITUD = {

  Validacion_PST: 1,
  CAMBIO_RNT: 2,
  CAMBIO_RAZON_SOCIAL: 3,
  CAMBIO_YEAR: 4
};

export const NOTIFICATION_TYPE = {
  Correo  : 1,
  Movil   : 2,
  Evento  : 3,
  Reserva : 4,
  RNT     : 5
}

export const nameOfMunicipalities = {
  "Buenaventura":"Buenaventura",
  'GuadalajaraDeBuga':'Guadalajara De Buga',
  'Cali':'Cali',
  'CalimaElDarien':'Calima El Darién',
  'Cartago':'Cartago',
  'ElCerrito':'El Cerrito',
  'Ginebra':'Ginebra',
  'LaUnion':'La Unión',
  'Jamundi':'Jamundí',
  'Roldanillo':'Roldanillo',
  'Tulua':'Tuluá',
  'Yotoco':'Yotoco',
  'Yumbo':'Yumbo',
}
