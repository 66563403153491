import { createContext, useEffect, useState } from 'react';
import { apiRestGet } from '../services/request';

export const GeneralConfigContext = createContext(null);

export function GeneralConfigProvider(props) {
  const [generalConfig, setGeneralConfig] = useState(null);

  const getGeneralConfig = async () => {
    let generalConfigData = sessionStorage.getItem('generalConfig');

    if (generalConfigData === null) {
      const generalDataResponse = await apiRestGet(
        process.env.NEXT_PUBLIC_AUTH_CONFIG_SERVICE_URL + 'generalData'
      );

      if (generalDataResponse !== undefined) {
        generalConfigData = generalDataResponse.data;
        sessionStorage.setItem(
          'generalConfig',
          JSON.stringify(generalConfigData)
        );
        setGeneralConfig(generalConfigData);
      }
    } else {
      setGeneralConfig(JSON.parse(generalConfigData));
    }
  };

  useEffect(() => {
    getGeneralConfig();
  }, []);

  return (
    <GeneralConfigContext.Provider value={{ generalConfig }}>
      {props.children}
    </GeneralConfigContext.Provider>
  );
}
